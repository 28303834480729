&.spa-tab-level--2 {

  .spa-tab__tab-element {
    padding-right: $spa-tab-icon-spacing-L2; 
    margin-right: $spa-tab-icon-spacing-L2; 

    &:focus {
      border-color: transparent;
    }

    &::after {
      bottom: -1px;
    }
  }
}

&.spa-tab-level--3 {

  .spa-tab__tab-element {

    font-family: $spa-tab-font-family-L3;
    font-size: $spa-tab-txt-size-L3;
    line-height: $spa-tab-txt-line-height-L3;
    padding-right: $spa-tab-icon-spacing-L3; 
    margin-right: $spa-tab-icon-spacing-L3; 


    padding-bottom: 0;
    &:after {
      display: none;
    }


    border: 1px solid transparent;
    padding: 2px $spacing-12 0 $spacing-12;
    border-radius: 8px;

    &.spa-tab__activate-on-load {
      color: $spa-tab-txt-color--focus;
      border-color: $spa-tab-txt-color--focus;

      &:focus {
        border-color: $spa-tab-txt-color--hover;
        &:hover {
          border-color: $spa-tab-txt-color--hover;
        }
      }
    }

    &:hover {
      border-color: $spa-tab-txt-color--hover;
    }

    &:focus {
      border-color: $spa-tab-txt-color--focus;
      &:hover {
        border-color: $spa-tab-txt-color--hover-active;
      }
    }
  }

  .spa-tab__control {
    padding-bottom: 6px;
    .spa-icon {
      margin-top: -8px;
    }
  }

  .spa-tab__icon--small {
    bottom: 0;
    right: 2px;
  }
}