
$spa-tab-txt-size: rem-calc(16px);
$spa-tab-txt-size-L3: rem-calc(14px);
$spa-tab-txt-line-height: rem-calc(24px);
$spa-tab-txt-line-height-L3: rem-calc(40px);
$spa-tab-txt-color: $color-gray;
$spa-tab-font-family: $cnx-family, $cnx-font-fallbacks;
$spa-tab-font-family-L3: roboto-regular, Arial, "Helvetica Neue", Helvetica, Roboto, $spa-font-family-sans;
$spa-tab-txt-color--hover: $color-royal-one;
$spa-tab-txt-color--focus: $color-brand-secondary;
$spa-tab-txt-color--hover-active: $color-royal-one;


$spa-tab-icon-spacing: 8px;
$spa-tab-icon-spacing-large: 8px;
$spa-tab-icon-spacing-L2: 10px;
$spa-tab-icon-spacing-L3: 10px;
$spa-tab-icon-size: 24px;
$spa-tab-icon-size-small: 8px;



@mixin spa-tab-states() {
  &.spa-tab__activate-on-load,
  &.spa-tab__list-item-dropdown--active {
    .spa-tab__icon--small .spa-icon {
      transform: rotate(180deg);
    }
    color: $spa-tab-txt-color--focus;
    &:after {
      background-color: $spa-tab-txt-color--focus;
    }
    .spa-tab__icon--default {
      display: none;
    }
    .spa-tab__icon--focus {
      display: inline-block;
    }
  }

  &:focus,
  &:hover,
  &:active {
    color: $spa-tab-txt-color--hover;
    border-color: $spa-tab-txt-color--hover;
    &:after {
      background-color: $spa-tab-txt-color--hover;
    }
    .spa-tab__icon--focus,
    .spa-tab__icon--default {
      display: none;
    }
    .spa-tab__icon--hover {
      display: inline-block;
    }
  }
}
