@import '/apps/wt/webtools/sparta_build_server/tmp/9C7439D6/artifacts/components/utilities/global/sparta-style-utility/4.9.5/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;
[data-sparta-container] .spa-tab {
  @import '_config.scss';


  &__container + .spa-panels__wrapper,
  &__container {
    position: relative;
    width: 100%;
  }


  &__row {
    &:before {
      border-bottom: 2px solid $color-gray-s20;
      bottom: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    .spa-tab__column {
      padding-left: $small-gutter;
      padding-right: $small-gutter;

      @media #{$medium-up} {
        padding-left: $medium-gutter;
        padding-right: $medium-gutter;
      }

      @media #{$large-up} {
        padding-left: $large-gutter;
        padding-right: $large-gutter;
      }
    }
  }


  &__tab-wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: 0;
    padding: 0;


    overflow-x: scroll;
    scrollbar-width: none; 
		-ms-overflow-style: none; 
		&::-webkit-scrollbar { 
			width: 0;
			height: 0;
		}

    .spa-tab__tab-element {
      &:hover,
      &:active {
        border-color: transparent;

        &::after {
          height: 3px;
        }

        .spa-tab__icon--hover {
          &::after,
          &::before {
            background-image: icon(chevronDown, (color: $color-royal-one));
          }
        }
      }

      &:focus {
        outline: none;

                &:hover,
        &:active {
          border-color: $color-royal-one;
        }
      }
    }
  }


  &__control-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    width: 100%;
    height: 100%;

        &::before {
      background: $color-gray-s20; 
      content: " ";
      height: 2px;
      left: 0;
      position: absolute;
      top: 44px;
      width: 100%;
    }
  }


  &__tab-wrapper > .spa-tab__tab-element,
  &__tab-list-item {
    display: inline-block;
    vertical-align: top;
    list-style: none;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;

    &:last-child {
      .spa-tab__tab-element {
        margin-right: 0;
      }
    }
  }

  &__tab-list-item {
    border: 1px solid transparent; 
  }


  &__control-wrapper {
    .spa-tab__control {
      position: absolute;
      left: 0;
      width: 24px;
      padding: $spa-tab-icon-spacing 0 6px;
      top: 6px;
      flex-shrink: 0;
      border: 1px solid transparent;
      border-radius: 4px;
      z-index: 1;
      background: $color-brand-tertiary;
      transition: all $spa-standard-transition-time $spa-standard-transition-easing $spa-standard-transition-delay;

      .spa-icon {
        width: 100%;
      }

      &:before {
        content: ' ';
        position: absolute;
        top: 0;
        width: 50%;
        left: calc(100% + 1px);
        height: calc(100% - 1px);
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
      }

      &.spa-tab__control--hidden {
        width: 0;
        border-color: transparent;
        border-width: 0px;
        overflow: hidden;

        &-faux {
          opacity: 0;
          width: 0;
          cursor: default;
        }
      }

      &.spa-tab__control--next {
        left: auto;
        right: 0;
        .spa-tab__icon {
          position: relative;
          left: 1px;
        }
        &:before {
          left: auto;
          right: calc(100% + 1px);
          background: linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        }
      }


      @include spa-tab-states();


      &.spa-tab__control--hidden-faux {
        &,
        &:hover,
        &:focus {
          border-color: transparent;
          .spa-tab__icon--focus,
          .spa-tab__icon--hover,
          .spa-tab__icon--default {
            display: none;
          }
        }

        .spa-tab__icon--disabled {
          display: inline-block;
        }

        .icon-caret-left--gray-20 {
          &:hover {
            background-image: icon(caretLeft, (color: $color-gray-s20));
          }

                  }
        .icon-caret-right--gray-20 {
          &:hover {
            background-image: icon(caretRight, (color: $color-gray-s20));
          }
        }
      }
    }

    .spa-tab__tab-wrapper {
      align-self: flex-end;
      transition: width $spa-standard-transition-time $spa-standard-transition-easing $spa-standard-transition-delay;
      transition-delay: $spa-standard-transition-time;


      &,
      & > li {
        margin: 0;
        padding: 0;
      }
    }
    .spa-badge {
      margin-right: 4px;

      &--numeric {
        margin-bottom: 5px;
      }
    }
  }


  &__control,
  &__tab-element {

    position: relative;
    display: block;
    background: none repeat scroll 0 0 transparent;
    border: medium none;
    border-spacing: 0;
    color: $spa-tab-txt-color;
    &:visited {
      color: $spa-tab-txt-color;
    }
    font-family: $spa-tab-font-family;
    font-size: $spa-tab-txt-size;
    line-height:$spa-tab-txt-line-height;
    font-weight: normal;
    list-style: none outside none;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: none !important;
    text-indent: 0;
    outline: none;


    padding-top: $spacing-12;
    padding-bottom: $spacing-8;
  }


  &__tab-element {
    transition: color $spa-standard-transition-time $spa-standard-transition-easing $spa-standard-transition-delay, border-color $spa-standard-transition-time $spa-standard-transition-easing $spa-standard-transition-delay;
    border: 1px solid transparent;
    border-radius: 2px;


    padding-right: $spa-tab-icon-spacing; 
    padding-left: $spa-tab-icon-spacing; 
    margin-right: 0;

    @media #{$large-up} {
      margin-right: $spa-tab-icon-spacing; 
    }

    &:after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: $spa-tab-icon-spacing;
      width: calc(100% - 18px);
      height: 2px;
      background-color: transparent;
      transition: background-color $spa-standard-transition-time $spa-standard-transition-easing $spa-standard-transition-delay;
    }


    @include spa-tab-states();
  }


  &__icon {
    .spa-icon {
      display: none;
      vertical-align: middle;
      background-position: 0 center;
      background-size: 100%;
      border: 1px solid transparent;
      height: 100%;
      margin-top: -2px;
      min-height: $spa-tab-icon-size;
      width: $spa-tab-icon-size;
    }

    &--small {
      margin: auto;
      .spa-icon {
        position: relative;
        min-height: 18px;
        width: 18px;
        display: inline-block;
        &::before,
        &::after {
          background-size: 100%;
          background-image: icon(chevronDown, (color: $color-gray)); 
        }
      }
    }

    .spa-tab__icon--default {
      display: inline-block;
    }
  }


  &__list-item-dropdown {
    display: none;
    width: 100%;
    background: $color-brand-tertiary;
    border-radius: 0;
    margin-top: 8px;
    overflow-y: auto;

    &--active {
      display: block;
      border-color: transparent;
    }

    &--navigation {
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: 0;
      z-index: $layer-nonmodal-dialogs;
    }

    &.spa-tab__layout-flush {
      padding: 0;
      box-shadow: none;
      .spa-card__head,
      .spa-card__body,
      .spa-card__foot {
        margin: 0;
        padding-left: 0;
      }

      .spa-card__title,
      .spa-card__head-content {
        padding-left: 0;
      }

      .spa-card__foot {
        margin-top: $spacing-16;
      }
    }

    &-spinner {
      .spa-icon {
        border: 1px solid transparent;
      }
    }


    .spa-card__title {
      max-width: 100%;
    }
  }


  &--full-width {
    .spa-tab__control-wrapper {
      .spa-tab__control {
        position: relative;

        &--hidden-faux {
          width: 24px;
          opacity: 1;
        }
      }
    }
  }


  &__container {
    @import '_sparta-tab-levels.scss';
  }

  @media #{$large-up} {
    &__custom--width {
      &.spa-tab__list-item-dropdown--navigation {
        position: fixed;
        width: 100%;
      }
    }

    &__auto--width {
      &.spa-tab__list-item-dropdown--navigation {
        position: fixed;
        width: auto;
      } 
    }
  }
}
